import React, {useEffect, useState} from 'react';
import {
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    IconButton,
    LinearProgress,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Tooltip,
    Typography
} from '@mui/material';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import {callGameEditorAPI} from "./apiUtils";

// Define common styles
const cellStyle = {
    fontSize: '0.75rem',  // Smaller font size for table cell content
    border: '1px solid #ccc',  // Common border for cells
    textAlign: 'center',  // Center text horizontally
};

const headerCellStyle = {
    ...cellStyle,
    fontSize: '0.875rem',  // Slightly bigger font for header cells
    fontWeight: 'bold',  // Make header text bold
};

const imageStyle = {
    width: '80px',
    height: 'auto',
    borderRadius: '8px',
};

// Styled title
const titleStyle = {
    fontSize: '1.25rem',
    fontWeight: 'bold',
    textAlign: 'center',
    marginBottom: '16px',
};

export const ChallengeInfoDialog = ({open, onClose, region_code, onPlayChallenge}) => {
    const [challenges, setChallenges] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        // Fetch challenges when the dialog opens
        if (open) {
            setLoading(true);
            setError(null); // Reset error state
            callGameEditorAPI(`/daily_challenges/${region_code}`, 'GET', {is_return_statistics: true})
                .then((data) => {
                    setChallenges(data);
                })
                .catch((err) => {
                    setError('Failed to load challenges');
                })
                .finally(() => {
                    setLoading(false);
                });
        }
    }, [open, region_code]); // Only run this effect when the dialog is opened

    return (
        <Dialog open={open} onClose={onClose} maxWidth="xl" fullWidth>
            <DialogTitle>
                <Typography sx={titleStyle}>Challenges</Typography>
            </DialogTitle>
            <DialogContent>
                {loading && <LinearProgress/>}
                {error && <Typography color="error">{error}</Typography>}
                {!loading && challenges.length === 0 && !error && (
                    <Typography>No challenges available</Typography>
                )}
                {!loading && !error && (
                    <TableContainer sx={{overflowX: 'auto'}}>
                        <Table sx={{minWidth: 650, borderCollapse: 'collapse'}}>
                            <TableHead>
                                <TableRow>
                                    <TableCell sx={headerCellStyle}>Object</TableCell>
                                    <TableCell sx={headerCellStyle}>Image</TableCell>
                                    <TableCell sx={headerCellStyle}>Region</TableCell>
                                    <TableCell sx={headerCellStyle}>Challenge Type</TableCell>
                                    <TableCell sx={headerCellStyle}>Created By</TableCell>
                                    <TableCell sx={headerCellStyle}>Creation Time</TableCell>
                                    <TableCell sx={headerCellStyle}>Successful Players</TableCell>
                                    <TableCell sx={headerCellStyle}>Successful Games</TableCell>
                                    <TableCell sx={headerCellStyle}>Action</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {/* Each challenge is a row */}
                                {challenges.map((challenge) => (
                                    <TableRow key={challenge.challenge_code}>
                                        <TableCell sx={cellStyle}>{challenge.target_image?.object_name}</TableCell>
                                        <TableCell sx={cellStyle}>
                                            <Box display="flex" justifyContent="center">
                                                <img
                                                    src={challenge.target_image?.internal_processed_image_url}
                                                    alt="Challenge"
                                                    style={imageStyle}
                                                />
                                            </Box>
                                        </TableCell>
                                        <TableCell sx={cellStyle}>{challenge.region_code}</TableCell>
                                        <TableCell sx={cellStyle}>{challenge.challenge_type}</TableCell>
                                        <TableCell sx={cellStyle}>{challenge.created_by_user_nickname}</TableCell>
                                        <TableCell sx={cellStyle}>
                                            {challenge.creation_time
                                                ? new Date(challenge.creation_time).toLocaleString()
                                                : 'N/A'}
                                        </TableCell>
                                        <TableCell sx={cellStyle}>
                                            {challenge.statistics.num_of_successful_players} / {challenge.statistics.num_of_players}
                                        </TableCell>
                                        <TableCell sx={cellStyle}>
                                            {challenge.statistics.num_of_successful_games} / {challenge.statistics.num_of_games}
                                        </TableCell>
                                        <TableCell sx={cellStyle}>
                                            <Tooltip title={challenge.challenge_code}>
                                                <IconButton
                                                    color="primary"
                                                    onClick={() => onPlayChallenge(challenge.challenge_code)}
                                                >
                                                    <PlayArrowIcon/>
                                                </IconButton>
                                            </Tooltip>
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                )}
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose} color="secondary" sx={{fontSize: '0.75rem'}}>
                    Close
                </Button>
            </DialogActions>
        </Dialog>
    );
};
